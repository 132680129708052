export default {
    getMessage(state){
        return state.message;
    },
    getType(state) {
        return state.type;
    },
    getTitle(state) {
        return state.title;
    },
    isMsg(state) {
        return state.type !== '';
    },
    getConfirmMessage: state => state.confirmMessage,
    getConfirmTitle: state => state.confirmTitle,
    isConfirmMessage: state => state.confirmMessage !== '',
    confirmState: state => state.confirmState
};