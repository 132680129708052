import defaultCityzenzState from "@/store/modules/cityzenz/defaultCityzenzState";

export default {
    setOrganisations(state, organisations){
        state.organisations = organisations;
    },
    setZoneList(state, zones){
        state.zones = zones;
    },
    setNodesingle(state, singlesensor) {
        state.nodesingle = singlesensor;
    },
    setNodeLast(state, sensor) {
        state.sensor = sensor;
    },
    setNodesList(state, list) {
        state.nodeList = list;
    },
    setOnlineList(state, list) {
        state.onlineList = list;
    },
    setNodeMeta(state, metaData) {
        state.metaList = metaData;
    },
    setScheduleList(state, list) {
        state.scheduleList = list
    },
    setPolys(state, polys) {
        state.polys = polys;
    },
    clearCityzenzState(state) {
        Object.assign(state, defaultCityzenzState());
    },
};