import defaultNotifyState from "@/store/modules/notify/defaultNotifyState";

export default {
    clearMessage(state){
        Object.assign(state, defaultNotifyState());
    },
    errorMsg(state, payload){
        state.type = 'error';
        state.title = "ERROR!! : ";
        state.message = payload;
    },
    warningMsg(state, payload) {
        state.type = 'warning';
        state.title = "WARNING!! : ";
        state.message = payload;
    },
    infoMsg(state, payload) {
        state.type = 'info';
        state.title = "For your information : ";
        state.message = payload;
    },
    successMsg(state, payload) {
        state.type = 'success';
        state.title = "SUCCESS!! : ";
        state.message = payload;
    },
    confirmMessage(state, payload) {
        state.confirmTitle = payload.title;
        state.confirmMessage = payload.message;
    },
    confirmState(state, payload) {
        state.confirmState = payload;
    },
    clearConfirmMessage(state) {
        state.confirmMessage = '';
        state.confirmTitle = '';
    },
    clearConfirmState(state) {
        state.confirmState = '';
    }
};