import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            organisations: null,
            zones: null,
            polys:null,
            sensor: null,
            nodes: null,
            nodes2: null,
            nodesingle: null,
            onlineList: null,
            metaList: null,
            nodeList: null,
            scheduleList: null,
            tempNodeLast : {"id":319001,"sensors":[{"port_id":248,"measurements":{"P.L1.W":{"value":9,"time":"2023-05-17T09:03:49Z"},"P.W":{"value":9,"time":"2023-05-17T09:03:49Z"},"I1.mA":{"value":0,"time":"2023-05-17T09:03:49Z"},"V1.mV":{"value":243800,"time":"2023-05-17T09:03:49Z"}}}],"ios":{"inputs":{"in.1":{"value":1,"time":"2023-05-17T09:03:49Z"}},"outputs":{"out.1":{"value":0,"time":"2023-05-17T09:02:32Z"},"out.2":{"value":1,"time":"2023-05-17T09:02:32Z"},"out.3":{"value":0,"time":"2023-05-17T09:02:32Z"},"out.4":{"value":0,"time":"2023-05-17T09:02:32Z"},"out.5":{"value":1,"time":"2023-05-17T09:02:32Z"},"out.6":{"value":0,"time":"2023-05-17T09:02:32Z"},"out.7":{"value":0,"time":"2023-05-17T09:02:32Z"},"out.8":{"value":0,"time":"2023-05-17T09:02:32Z"},"out.9":{"value":0,"time":"2023-05-17T09:02:32Z"}}},"status":{"errors.1":0,"connected.1":true,"mode.out.1":"schedule","mode.out.2":"schedule","mode.out.3":"schedule","errors.2":0,"connected.2":true,"mode.out.4":"schedule","mode.out.5":"schedule","mode.out.6":"schedule","errors.3":0,"connected.3":true,"mode.out.7":"schedule","mode.out.8":"schedule","mode.out.9":"schedule","errors.0":0,"connected.0":true},"connection":{"last_seen":"2023-05-17T09:03:50Z","online":true}},
            tempNodeMeta: {"id":319001,"hardware":{"hardware_id":"0004A30B01669192","modules":[{"type":"czii","serial_number":null,"port_id":0,"firmware":"1.1.3"},{"type":"p1_meter","port_id":248},{"type":"czi","serial_number":null,"port_id":1,"firmware":"2.2.0"},{"type":"czi","serial_number":null,"port_id":2,"firmware":"2.2.0"},{"type":"czi","serial_number":null,"port_id":3,"firmware":"2.2.0"}]},"ios":{"inputs":{"in.1":{"name":"DOOR","path":"io.0.2"}},"outputs":{"out.1":{"name":"OUT1","path":"io.1.1"},"out.2":{"name":"OUT2","path":"io.1.2"},"out.3":{"name":"OUT3","path":"io.1.3"},"out.4":{"name":"OUT4","path":"io.2.1"},"out.5":{"name":"OUT5","path":"io.2.2"},"out.6":{"name":"OUT6","path":"io.2.3"},"out.7":{"name":"OUT7","path":"io.3.1"},"out.8":{"name":"OUT8","path":"io.3.2"},"out.9":{"name":"OUT9","path":"io.3.3"}}},"location":{"latitude":51.472736,"longitude":5.671732,"type":"gps"},"user_field":"koffer"},
        };
    },
    mutations,
    actions,
    getters
};