import defaultAuthState from "@/store/modules/auth/defaultAuthState";
import store from '@/store/index';

export default {
    AuthRequest(state) {
        state.status = "loading";
    },
    AuthSuccess(state, user){
       state.status = "success";
       state.authenticated = true;
       if(user.username) {
           state.user.username = user.username;
       }
       if(state.user.organisations.length > 0) {
           const last = state.lastKnownOrg;
           const valid = state.user.organisations.some((elem) => elem.id === last.organisation);
           if (last.username === user.username && valid) {
               state.user.active_org = last.organisation;
           } else {
               state.user.active_org = state.user.organisations[0].id;
           }
           state.lastKnownOrg  = {
               username: state.user.username,
               organisation: state.user.active_org
           };
       } else {
           state.authenticated = false;
           store.commit('not/errorMsg', "User has no permissions", {root: true});
       }
    },
    AuthError(state, error) {
        if (error.response.status === 401){
            state.status = error.response.data.message;
        } else {
            state.status = "error";
        }
    },
    AuthReset(state){
        Object.assign(state, defaultAuthState());
    },
    AuthLogout() {
        store.commit('ctz/clearCityzenzState', null, { root: true });
        store.commit('sched/clearScheduleState', null, { root: true });
        store.commit('not/clearMessage', null, { root: true });
        store.commit('auth/AuthReset');
    },
    setOrganisations(state, organisations){
        state.user.organisations = organisations;
    },
    setActiveOrganisation(state, org) {

        const user = {
            username: state.user.username,
            organisation: org
        }
        state.lastKnownOrg  = user;
        state.user.active_org = org;
    }
};