import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/index.js';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import i18n from './i18n'
import axios from "axios";

Vue.config.productionTip = false

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.component('loading-bar', () => import('@/components/Standard/LoadingBar.vue'));
Vue.component('message', () => import('@/components/Standard/Message.vue'));

// Global configuration via environment variables
// These settings are plugged in via conf/env-config.js  (directly used in index.html)
Vue.prototype.$API_URL = window.VUE_API_URL;

new Vue({
    vuetify,
    router,
    store: store,
    i18n,
    render: h => h(App)
}).$mount('#app')

if (! Vue.prototype.$API_URL) {
    // remind us to set VUE_API_URL in docker runner
    console.log("Warning: deployment error, empty $API_URL.");
}


/**
 * Make an AXIOS instance for connecting to Harm's API
 * Set the timeout to 5000 ms. Because the API has a lot to process and the request can easily take up to 3 seconds.
 * @type {AxiosInstance}
 */
Vue.prototype.$axios_api = axios.create({
    baseURL: Vue.prototype.$API_URL + '/api/1.0/',
    timeout: 8000,
    withCredentials: true,
});


/**
 * Interceptor for Axios instance catching the 200/201 and the 401
 */
Vue.prototype.$axios_api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    // Do something with request error
    if (error.response.status === 401) {
        // clear authentication data
        store.commit('auth/AuthReset');
        // save current page to return to after logging in
        if (router['history'].current.name !== 'Login') router.push({name: 'Login'});
    }
    return Promise.reject(error);
});

export const $axios = Vue.prototype.$axios_api;