/**
 * @module apiHelpers
 *
 * @description functions to help communicate with the API.
 */
import { $axios } from '@/main.js';
import store from "@/store";


/**
 * @function getScheduleIds
 * @description function that returns an array of schedule ids
 *
 * @param {number} org_id organisation_id. Won't work without an organisation.
 * @param {number} mode 0 = all, 1 = published, 2 = active, 3 = published & active
 * @returns {Promise<AxiosResponse<any>>}
 */
const getScheduleIds = async function(org_id = -1, mode = 0) {
    let url_text;
    switch(mode) {
        case 0:
            url_text = `orgs/${org_id}/schedules`;
            break;
        case 1:
            url_text = `orgs/${org_id}/schedules?published=true`;
            break;
        case 2:
            url_text = `orgs/${org_id}/schedules?active=true`;
            break;
        case 3:
            url_text = `orgs/${org_id}/schedules?published=true&active=true`;
            break;
    }
    return $axios.get(url_text)
    .then((response) => {
        return response.data.schedules;
    })
    .catch((error) => {
        console.log("Error in 'getScheduleIds' : " + error);
    })
};

/**
 * @function getNodeIds
 * @description function that returns an array of node Ids from the API.
 *
 * @param {number} org_id organisation id of which the nodeIds must be returned.
 *               if omitted, returns ALL the nodes of ALL the organisations the user belongs to
 * @param {number} format return format.
 *                  0 = array ("/api/1.0/devices/14561", "/api/1.0/devices/14564")
 *                  1 = string ("14561,14564,14567")
 * @returns {Promise<AxiosResponse<any>>} Array of nodeIds
 */
const getNodeIds = async function(org_id = -1, format = 0) {
    let apiString;
    if (!org_id || org_id === -1){
        apiString = "nodes";
    } else {
        apiString = `orgs/${org_id}/nodes`;
    }

    return $axios.get(apiString)
        .then((response) => {

            let nodeArray = "";
            if(format !== 0) {
                const nodeIds = response.data;
                nodeIds['nodes'].forEach((elem) => {
                    nodeArray += elem.substring(15) + ",";
                });
                nodeArray = nodeArray.substring(0, nodeArray.length - 1);
            } else {
                nodeArray = response.data;
            }
            return nodeArray;
        })
        .catch((error) => {
            console.log("Error in 'getNodeIds' : " + error);
        })
};

/**
 * @function getSchema
 * @description function that returns the Schedules for a Schedule id/version or uid.
 *
 * There are two options:
 *      - Provide schedule_id and version (If no version number is provided, the
 *        latest is returned.)
 *      - Provide schedule_uid.
 *
 * @param org_id organisation
 * @param {Object} data  ( schedule_id: {Number}, schedule_version: {Number} } OR { schedule_uid: {Number} }
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSchema = async function(org_id, data) {
    // TODO not implemented in API yet
    let schedule_uid, schedule_id, schedule_version = null;
    let url_text;
    if ('schedule_uid' in data) {
        schedule_uid = data.schedule_uid;
        url_text = `orgs/${org_id}/schedules/${schedule_uid}`;
    } else {
        schedule_id = data.schedule_id;
        schedule_version = data.schedule_version;
        url_text = `orgs/${org_id}/schedules/${schedule_id}/versions/${schedule_version}`;
    }

    return $axios.get(url_text)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("Error in 'getSchema' : " + error);
        return {
            status: error.response.status,
            errorMsg: error.response.statusText
        };
    })
};

/**
 * @function serializeJson
 * @description prepares a Json object to be sent to the API. fixes some fields that shouldn't be in there.
 *              like empty date strings.
 *
 * @param {object} jsonScheduleObject the Json file to be fixed
 * @returns {object} Fixed Json file. Ready to be sent to API.
 */
const serializeJson = function(jsonScheduleObject) {
    let myJsonObject = {
        name: jsonScheduleObject.name,
        active: jsonScheduleObject.active,
        published: jsonScheduleObject.published,
        schedules: [
            {
                io_id: "out.1",
                profiles: [...jsonScheduleObject.schedules[0].profiles]
            },
            {
                io_id: "out.2",
                profiles: [...jsonScheduleObject.schedules[1].profiles]
            },
            {
                io_id: "out.3",
                profiles: [...jsonScheduleObject.schedules[2].profiles]
            }
        ],
    };

    for (let schedule of myJsonObject.schedules) {
        for (let profile of schedule.profiles) {
            delete profile.profile_id;
            if (!profile.startDate) {
                delete profile.startDate;
                delete profile.endDate;
                for (let switch1 of profile.switches) {
                    delete switch1.switch_id;
                }
            }
        }
    }
    return myJsonObject;
};


/**
 * @function getNodeScheduleList
 * @description function that returns an array of all schedules of the nodes in the API.
 *
 * @param {string} nodeStr if a nodeStr is given, it will use this. Otherwise fetch a list from the API
 * @returns {Promise<AxiosResponse<any> | {status: number | ((code: number) => any), errorMsg: string}>}
 */
const getNodeScheduleList = async function (nodeStr = "") {
    let nodeArray = "";
    if(nodeStr === "") {
        const nodeIds = await getNodeIds();
        nodeIds['nodes'].forEach((elem) => {
            nodeArray += elem.substring(15) + ",";
        });
        nodeArray = nodeArray.substring(0, nodeArray.length - 1);
    } else {
        nodeArray = nodeStr;
    }
    return $axios.get(`nodes/schedule/${nodeArray}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'getNodeScheduleList' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
};

/**
 * @function getNodesOnlineList
 * @description function that returns an array of online information of the nodes in the API.
 *              (this is a FAST get. because it uses the '?fields=...' option
 * @param {string} nodeStr if a nodeStr is given, it will use this. Otherwise fetch a list from the API
 * @returns {Promise<AxiosResponse<any> | {status: ((code: number) => any) | number, errorMsg: string}>}
 */
const getNodesOnlineList = async function (nodeStr = "") {
    let nodeArray = "";
    if(nodeStr === "") {
        const nodeIds = await getNodeIds();
        nodeIds['nodes'].forEach((elem) => {
            nodeArray += elem.substring(15) + ",";
        });
        nodeArray = nodeArray.substring(0, nodeArray.length - 1);
    } else {
        nodeArray = nodeStr;
    }
    return $axios.get(`nodes/last/${nodeArray}?fields=connection`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'getNodesOnlineList' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
};

/**
 * @function getNodesMetaList
 * @description function that returns an array meta information of the nodes in the API.
 *
 * @param {string} nodeStr if a nodeStr is given, it will use this. Otherwise fetch a list from the API
 * @returns {Promise<AxiosResponse<any> | {status: ((code: number) => any) | number, errorMsg: string}>}
 */
const getNodesMetaList = async function(nodeStr = "") {
    let nodeArray = "";
    if(nodeStr === "") {
        const nodeIds = await getNodeIds();
        nodeIds['nodes'].forEach((elem) => {
            nodeArray += elem.substring(15) + ",";
        });
        nodeArray = nodeArray.substring(0, nodeArray.length - 1);
    } else {
        nodeArray = nodeStr;
    }
    return $axios.get(`nodes/meta/${nodeArray}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'getNodesMetaList' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
};

/**
 * @function getNodesUserfieldList
 * @description function that returns an array of user_field information of the nodes in the API.
 *               (this is a FAST get. because it uses the '?fields=...' option )
 *
 * @param {string} nodeStr if a nodeStr is given, it will use this. Otherwise fetch a list from the API
 * @returns {Promise<AxiosResponse<any> | {status: ((code: number) => any) | number, errorMsg: string}>}
 */
const getNodesUserfieldList = async function(nodeStr = "") {
    let nodeArray = "";
    if(nodeStr === "") {
        const nodeIds = await getNodeIds();
        nodeIds['nodes'].forEach((elem) => {
            nodeArray += elem.substring(15) + ",";
        });
        nodeArray = nodeArray.substring(0, nodeArray.length - 1);
    } else {
        nodeArray = nodeStr;
    }
    return $axios.get(`nodes/${nodeArray}?fields=user_field`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'getNodesMetaList' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
};

/**
 * @function getOrganisations
 * @description function that returns an array of organisations from the API.
 *
 * @returns {Promise<AxiosResponse<any>>} Array of nodeIds
 */
const getOrganisations = async function() {
    return $axios.get('orgs')
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'getOrganisations' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        })
};

/**
 * @function getZones
 * @desc if no zone is given, returns a list of known zones. Else returns information about the provided zone.
 *
 * @param {Number} org Organisation for which the user is making the call
 * @param {Number} zone Zone for which the info is needed. If omitted, gives a list of all zones.
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getZones = async function(org = -1, zone = -1){
    if(org === -1) {
        console.log("NO ORGANISATION PROVIDED WITH 'getZones'!!");
    }
    let url;
    if (zone === -1) {
        url = `orgs/${org}/zones`;
    } else {
        url = `orgs/${org}/zones/${zone}`;
    }
    return $axios.get(url)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'getZones' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
};

/**
 * @function fixLngLats
 * @description Helper function to convert the Leaflet coordinate objects to GeoJSON arrays.
 *
 * @param {array} latlngs array of latlng objects in Leaflet format {lat: xx, lng: xx}
 * @returns {array} array of 2-arrays (with longitude first and latitude second)
 */
const fixLngLats = function (latlngs){
    let lnglts = [];
    latlngs[0].forEach((el) => {
        lnglts.push([parseFloat(el.lng.toFixed(10)), parseFloat(el.lat.toFixed(10))]) // 6 decimals for long and lat coordinates
    });
    lnglts.push(lnglts[0]); // Make sure the last element is also the first element (for GEO shape)
    return lnglts;
}

/**
 * @function addZone
 * @description Add a zone. A zone must have a name. The rest is optional.
 *              Shape
 *
 * @param {number} org
 * @param {object} zone
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const addZone = async function(org = -1, zone) {
    if(org === -1) {
        console.log("NO ORGANISATION PROVIDED WITH 'addZone'!!");
    }
    const newZone = {
        "name": zone.name,
    }
    if (zone.city !== '') newZone["city"] = zone.city;
    /*
        Note about the GeoJSON multiPolygons as used by the API.
        (accuracy of the coordinates is 6 decimals.)
        {
            "type": "MultiPolygon",
            "coordinates": [
                [
                    {polygon},
                    {hole},
                    {hole}
                 ]
            ]
        }
        also a MultiPolygon has to start and end at the same point. This is fixed in the function fixLngLats !!!!
     */
    if (zone.latlngs) newZone["shape"] = {
        "type": "MultiPolygon",
        "coordinates": [[[
            ...fixLngLats(zone.latlngs)
            ]]]
    };
    return $axios.post(`orgs/${org}/zones`, newZone)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'addZone' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
}

const deleteZone = async function(org = -1, zoneId) {
    if(org === -1) {
        console.log("NO ORGANISATION PROVIDED WITH 'deleteZone'!!");
    }
    return $axios.delete(`orgs/${org}/zones/${zoneId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in 'deleteZone' : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
}

const editZone = async function(org = -1, zoneId, zoneObj){
    if(org === -1) {
        console.log("NO ORGANISATION PROVIDED WITH 'editZone'!!");
    }
    const newZone = {};
    if (zoneObj.name !== '') newZone["name"] = zoneObj.name;
    if (zoneObj.city !== '') newZone["city"] = zoneObj.city;
    // TODO implement adding shape or nodes

    return $axios.post(`orgs/${org}/zones/${zoneId}`, newZone)
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log("Error in 'editZone' : " + error);
        return {
            status: error.response.status,
            errorMsg: error.response.statusText
        };
    });
};

/**
 * @function assignScheduleToZone
 * @description assign a schedule to a zone. Organisation id is required. At the moment this is only possible
 *              with an schedule UID. (id/version combination is not yet implemented in the API)
 *
 * @param {number} org organisation id
 * @param {number} zoneId zone id
 * @param {number} scheduleUid schedule uid
 * @returns {Promise<axios.AxiosResponse<any>>} Message if and which nodes have succeeded
 */
const assignScheduleToZone = async function(org = -1, zoneId, scheduleUid) {
    if(org === -1) {
        console.log("NO ORGANISATION PROVIDED WITH 'editZone'!!");
    }
    let url_text = `orgs/${org}/zones/${zoneId}/schedule`;
    let body = {
        "uid": scheduleUid
    };
    return $axios.post(url_text, body)
    .then((response) => {
        if (response.status === 201){
            store.dispatch('not/successMsg', response.data);
        } else {
            store.dispatch('not/warningMsg', response.statusText);
        }
        return response.data;
    })
    .catch((error) => {
        console.log("Error in assignScheduleToZone : " + error);
        return {
            status: error.response.status,
            errorMsg: error.response.statusText
        };
    });

}

/**
 * @function editPublishedSchedule
 * @description When a schedule is published, the user can't change it anymore. EXCEPT the active or name fields.
 *              these can still be changed without having to publish a new version. The active setting is used to
 *              "delete" the schedule from the screen. (only active schedules are shown)
 *              A schedule change object is provided and must have a UID and one of the two following:
 *                  active : false
 *                  name: "Changed name"
 *
 * @param {number} org The organisation the user is a member of.
 * @param {object} schedule Schedule change object. See above.
 * @returns {Promise<axios.AxiosResponse<any> | {status: *, errorMsg: *}>}
 */
const editPublishedSchedule = async function(org = -1, schedule) {
    /*
        schedule = {
                        scheduleUid: 319123,
                        active: false,
                        name: "Changed name"
                    }
        One of these can be changed even when a schedule is published.
     */
    if(org === -1) {
        console.log("NO ORGANISATION PROVIDED WITH 'editPublishedSchedule'!!");
    }
    let url_text = `schedules/uid/${schedule['scheduleUid']}`;
    const keys = Object.keys(schedule);
    let body;
    if (keys.includes("active")){
        body = {
            "active": false
        };
    } else if (keys.include("published")) {
        body = {
            "name": schedule['name']
        };
    }
    return $axios.post(url_text, body)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log("Error in editPublishedSchedule : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
}

const setUserfield = (nodeId, userField) => {
    let url_text = `nodes/${nodeId}`;
    let body = {
        "user_field": userField
    };
    return $axios.post(url_text, body)
        .then((response) => {
            if (response.status === 201){
                store.dispatch('not/successMsg', "User field successfully updated.");
            } else {
                store.dispatch('not/warningMsg', response.statusText);
            }
            return response.data;
        })
        .catch((error) => {
            console.log("Error in setUserfield : " + error);
            return {
                status: error.response.status,
                errorMsg: error.response.statusText
            };
        });
};




export { getScheduleIds, getSchema, serializeJson, getNodeIds, getNodesUserfieldList, getOrganisations,
    getNodeScheduleList, getNodesOnlineList, getNodesMetaList, getZones, addZone, deleteZone, editZone,
    assignScheduleToZone, editPublishedSchedule, setUserfield };