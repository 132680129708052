const defaultCityzenzState = () => {
    return {
        organisations: null,
        zones: null,
        polys: null,
        nodes: null,
        sensor: null,
        nodes2: null,
        nodesingle: null,
        onlineList: null,
        nodeList: null,
        metaList: null,
        scheduleList: null
    };
};

export default defaultCityzenzState;