import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            current: {
                start: '',
                end: '',
                type: ''
            },
            events: [],
            eventsRange: [],
            weekinfo: {},
            sunCalc: {},
            schedules: [],
            pickedDate: '',
            singleSchedule: {},
            highestScheduleId: 0,
            error: '',
            queryData: {
                schedule_id: -1,
                io_id: 0,
                profile_id: 0,
                crudType: ''
            },
            lastAdded: {
                id: -1,
                uid: -1,
                version: -1
            }
        };
    },
    mutations,
    actions,
    getters,
};