export default {
    StateOrganisations: state => state.organisations,
    StateZones: state => state.zones,
    StatePolys: state => state.polys,
    StateNodeLast: state => state.sensor,
    StateNodes: state => state.nodes,
    StateNodes2: state => state.nodes2,
    StateNodesingle: state => state.nodesingle,
    StateNodeList: state => state.nodeList,
    StateOnlineList: state => state.onlineList,
    StateNodeMeta: state => state.metaList,
    StateScheduleList: state => state.scheduleList,
    StateTempLast: state => state.tempNodeLast,
    stateTempMeta: state => state.tempNodeMeta
};