import Vue from "vue";

export default {
    AuthRequest({ commit, dispatch }, user) {
        commit('AuthRequest');
        Vue.prototype.$axios_api.defaults.headers.common['X-Username'] = user.username;
        return Vue.prototype.$axios_api.post('login', { "username": user.username, "password": user.password })
            .then(response => {
                dispatch('getOrganisations').then(() => {
                    commit('AuthSuccess', {
                        username: user.username,
                    });
                })
                return response;
            })
            .catch((error) => {
                commit('AuthError', error);
                console.log("There was an error logging in on api : " + error);
                return error;
            });
    },
    AuthLogout({ commit }) {
        return Vue.prototype.$axios_api.post('logout')
            .then(() => {
                commit('AuthLogout');
            })
            .catch((error) => {
                console.log('There was an error while logging out (AuthError) : ', error);
                commit('AuthLogout');
            });
    },
    getOrganisations({ commit }) {
        const axios = Vue.prototype.$axios_api;
        return axios.get('orgs')
            .then(response => {
                commit('setOrganisations', response.data['orgs']);
                return response.data;
            }).catch((error) => {
                commit('setOrganisations', null);
                console.log("There was an error while getting Organisations : " + error);
            });
    }
};
