const defaultAuthState = () => {
    return {
        user: {
            username: null,
            organisations: [],
            active_org: null,
        },
        authenticated: "",
        status: "",
    };
};

export default defaultAuthState;
