const defaultNotifyState = () => {
    return {
        message: '',
        type: '',
        title: '',
        confirmMessage: '',
        confirmTitle: '',
        confirmState: '',
    }
};

export default defaultNotifyState;