import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#FFFFFF', //'#BE2830', //red-darken4
                secondary: '#FAFAFA', //'#D0D0CF', //red-darken1
                accent: '#BE2830', //colors.black,  //red-lighten4
                error: '#B00020',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
            dark: {
                primary: '#BE2830', //red-darken4
                secondary: '#D0D0CF', //red-darken1
                accent: colors.black,  //red-lighten4
                error: '#B00020',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
        },
    },
    icons: {
        iconfont: 'mdi',
    },
};

export default new Vuetify(opts);
