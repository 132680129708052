const defaultScheduleState = () => {
    return {
        current: {
            start: '',
            end: '',
            type: ''
        },
        events: [],
        eventsRange: [],
        weekNumber: null,
        weekinfo: {},
        sunCalc: {},
        schedules: null,
        pickedDate: '',
        singleSchedule: {},
        highestScheduleId: 0,
        error: '',
        queryData: {
                schedule_id: -1,
                io_id: 0,
                profile_id: 0,
                crudType: ''
        },
        lastAdded: {
            id: -1,
            uid: -1,
            version: -1
        }
    }
};

export default defaultScheduleState;