<template>
    <div class="pa-3 float-right">
        <select
            class="mySelect"
            name="language" v-model="$i18n.locale"
        >
            <option v-for="language in languages" :value="language.lang" :key="language.id">{{ language.name }}
                {{ language.flag }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "LangChoose",
    data() {
        return {
            languages: [
                {
                    "id": 1,
                    "lang": "en",
                    "name": "English (United States)",
                    "flag": "🇺🇸"
                },
                {
                    "id": 2,
                    "lang": "nl",
                    "name": "Nederlands",
                    "flag": "🇳🇱"
                }
            ],
        };
    },
}
</script>
<style scoped>
.mySelect {
    color: #9e9e9e;
    border: 1px #9e9e9e solid;
}
</style>