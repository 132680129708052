<template>
    <v-row
        class="d-flex justify-space-between"
        data-cy="menubar"
        no-gutters>
        <router-link to="/">
            <v-img class="pic1 my-1" contain height="2.8rem" src="@/assets/intemo_logo.png" width="240"
                   max-width="260px"></v-img>
        </router-link>

        <v-btn
            :id="`menubar-btn-${item.id}`"
            v-for="item in buttonHeaders"
            :key="item.id"
            :class="['grey--text', 'text-decoration-none', 'ma-2', 'mx-md-1', 'px-md-1', 'mx-lg-2', 'px-lg-2', { activeBTN: item.id === active }]"
            :color="item.id !== active ? 'primary' : ''"
            @click="handleClick(item.id)"
            :disabled="item.id === 2 || item.id === 6 || !isLoggedIn"
            :to="item.link"
            :small="buttonSmall"
            :x-small="buttonXSmall"
            >{{ $t(item.title) }}</v-btn>
        <v-spacer></v-spacer>


        <!--
                User and organisations is only for development purposes
                In a later stadium the user will only be able to choose between organisations
                if he has more than ONE organisation that he belongs to.
        -->
        <div class="d-inline-flex align-center" v-if="isLoggedIn && organisations.length > 1">
            <div class="mx-2">
                User : {{ getUser }}
            </div>
            <div v-if="loaded">
                <div class="mx-2">
                    <v-select
                        style="width: 170px; height: 35px"
                        v-model="select"
                        :items="organisations"
                        item-text="name"
                        item-value="id"
                        @change="changeOrganisation"
                        hide-selected
                        dense
                        solo
                    ></v-select>
<!--                    <select v-model="select" name="organisations" @change="changeOrganisation">-->
<!--                        <option v-for="org in organisations" :key="org.id" :value="org.id" id="org-select">{{ org.name }}</option>-->
<!--                    </select>-->
                </div>
            </div>
        </div>

        <span v-if="isLoggedIn" class="ma-2">
            <v-btn id="logout-button" class="ml-4 accent white--text" @click="Logout">{{ $t('menu.buttons.logout') }}</v-btn></span>
        <span v-else class="ma-2">
            <v-btn id="login-button" class="mx-2" color="accent" outlined :to="{name: 'Login'}">{{ $t('menu.buttons.login') }}</v-btn>
        </span>
    
    </v-row>

</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'Menubar',
    data: () => {
        return {
            active: null,
            buttonHeaders:[
                    {
                        id: 1,
                        title: "menu.buttons.map",
                        link: "/map",
                        isActive: false,
                    },
                    // {
                    //     id: 2,
                    //     title: "menu.buttons.projects",
                    //     link: "/projects",
                    //     isActive: false,
                    // },
                    {
                        id: 3,
                        title: "menu.buttons.sections",
                        link: "/zones",
                        isActive: false,
                    },
                    {
                        id: 4,
                        title: "menu.buttons.nodes",
                        link: "/stations",
                        isActive: false,
                    },
                    {
                        id: 5,
                        title: "menu.buttons.schedules",
                        link: "/schedule/info",
                        isActive: false,
                    },
                    {
                        id: 6,
                        title: 'menu.buttons.firmwareUpgrade',
                        link: '/firmware',
                        isActive: false,
                    },
                ],
            organisations: [],
            loaded: false,
            select: '',
        }
    },
    computed: {
        ...mapGetters({'isAuthenticated': 'auth/isAuthenticated', 'stateUser': 'auth/StateUser',
        'stateOrganisations': 'auth/StateOrganisations', 'stateActiveOrganisation': 'auth/StateActiveOrganisation'}),
        isLoggedIn() {
            if(this.isAuthenticated) this.populateData();
            return this.isAuthenticated;
        },
        buttonSmall() {
            return this.$vuetify.breakpoint.name === 'md';
        },
        buttonXSmall() {
            return this.$vuetify.breakpoint.name === 'sm';
        },
        getUser() {
            return this.stateUser.username;
        }
    },
    methods: {
        ...mapActions({'getOrganisations': 'auth/getOrganisations'}),
        Logout () {
            this.$store.dispatch('auth/AuthLogout');
            if (this.$route.path !== '/') this.$router.push('/');
        },
        handleClick(itemId) {
            this.buttonHeaders.forEach((el) => {
                el.isActive = el.id === itemId;
            });
            this.active = itemId;
        },
        populateData() {
            this.organisations = [];
            if (this.stateOrganisations) {
                this.stateOrganisations.forEach((el) => {
                    this.organisations.push(el);
                });
            } else {
                //this.$store.dispatch('not/errorMsg', "HELP!");
                console.log("POPULATE DATA : stateOrganisations is empty");
            }

            this.select = this.stateUser.active_org;
            this.loaded = true;
        },
        changeOrganisation(){
            const selected = this.select;
            this.$store.dispatch('auth/getOrganisations').then(() => {
                this.$store.commit('auth/setActiveOrganisation', selected);
                this.$router.go(0);
            })


        }
    },
};
</script>
<style>
.pic1 {
    background-color: #D3D3D3;
    border-radius: 10px;
    margin: 0 8px 0 0;
}
.activeBTN {
    background-color: #fbeaeb !important;
}
.black-button-text {
    color: black;
    font-weight: bold;
    font-size: 20px;
    pointer-events: none;
}
</style>