export default {
    errorMsg({commit}, payload){
        commit('errorMsg', payload);
    },
    warningMsg({commit}, payload) {
        commit('warningMsg', payload);
    },
    infoMsg({commit}, payload) {
        commit('infoMsg', payload);
    },
    successMsg({commit}, payload) {
        commit('successMsg', payload);
    },
    confirmMessage({commit}, payload) {
        commit('confirmMessage', payload);
    },
    confirmState({commit}, payload) {
        commit('confirmState', payload);
    },
    clearConfirmMessage({commit}) {
        commit('clearConfirmMessage');
    },
    clearConfirmState({commit}) {
        commit('clearConfirmState');
    }
};