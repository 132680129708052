import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
            message: '',
            type: '',
            title: '',
            confirmMessage: '',
            confirmTitle: '',
            confirmState: '',
        };
    },
    mutations,
    actions,
    getters,
};