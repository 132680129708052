import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import authModule from './modules/auth/index.js';
import cityzenzModule from './modules/cityzenz/index.js';
import Vue from "vue";
import scheduleModule from "./modules/schedule";
import notifyModule from './modules/notify';

Vue.use(Vuex);

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        ctz: cityzenzModule,
        auth: authModule,
        sched: scheduleModule,
        not: notifyModule,
    },
    // dit was persistedState .. veranderd in createPersistedState
    plugins: [ createPersistedState ()]
});

export default store;