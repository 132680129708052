import Vue from "vue";

// get data for a single node
async function getData({ commit }, sensornr) {
    const $axios = Vue.prototype.$axios_api;
    await $axios.get('nodes/' + sensornr + '')
        .then( response => {
            commit('setNodesingle', response.data);
            return response.data;
        }).catch((error) => {
            commit('setNodesingle', null);
            console.log("GET SINGLE DATA: There was an error : " + error);
        });
}
// get last data for one specific node
async function getLastData({ commit }, sensornr) {
    const $axios = Vue.prototype.$axios_api;
    await $axios.get('nodes/' + sensornr + '/last')
        .then( response => {
            commit('setNodeLast', response.data);
            return response.data;
        }).catch((error) => {
            commit('setNodeLast', null);
            console.log("GET LAST DATA: There was an error : " + error);
        });
}
function getNodesList ({ commit, rootGetters }) {
    let list = [];
    const $axios = Vue.prototype.$axios_api;
    const org = rootGetters['auth/StateUser'].active_org;
    return $axios.get(`orgs/${org}/nodes`)
    .then((response) => {
        response.data.nodes.forEach((element) => list.push(element.substr(15, element.length - 1)));
        list = list.join(",");
        commit('setNodesList', list);
        return response.data;
    })
    .catch((error) => {
        commit('setNodesList', null);
        console.log("NODES: There was an error getting the nodes list : " + error);
    });
}
function getNodesOnline({ commit }, nodesList) {
    const $axios = Vue.prototype.$axios_api;
    let myData = [];
    return $axios.get(`nodes/last/${nodesList}?fields=connection`)
    .then((response) => {
        response.data.forEach((element) => {
            myData.push({
                id: element.id,
                connection: {
                    last_seen: element.connection.last_seen,
                    online: element.connection.online
                }
            });
        });
        commit('setOnlineList', myData);
        return response.data;
    })
    .catch((error) => {
        commit('setOnlineList', null);
        console.log("NODES: There was an error getting the online statuses : " + error);
    });
}
// get metadata for a list of nodes
function getMetaNodes ({ commit }, nodesList) {
    let nodeMeta = [];
    const $axios = Vue.prototype.$axios_api;
    return $axios.get(`nodes/meta/${nodesList}`)
    .then((response) => {
        nodeMeta = response.data;

        return response.data;
    })
    .catch((error) => {
        console.log("There was an error during the get Multi Nodes Meta Data.. : " + error);
    })
    .finally(() => commit('setNodeMeta', nodeMeta));
}
/**
 * @function getSchedulesNodes
 * @desc returns the schedules which are active on the nodes that are given as a parameter.
 *
 * @param commit
 * @param nodesList List of nodes the info will be gotten from
 * @returns {Promise<void>}
 */
function getSchedulesNodes({commit}, nodesList) {
    const $axios = Vue.prototype.$axios_api;
    let nodesSchedules = [];
    return $axios.get(`nodes/schedule/${nodesList}`)
    .then((response) => {
        response.data.forEach((elem) => {
            nodesSchedules.push({
                id: elem.id,
                schedule: elem.schedule
            });
        });
        commit('setScheduleList', nodesSchedules);
        return response.data;
    })
    .catch((error) => {
        commit('setScheduleList', null);
        console.log("NODES: There was an error getting the schedule statuses : " + error);
    });
}

function getZones({commit, rootGetters}) {
    const $axios = Vue.prototype.$axios_api;
    let zones = [];
    const orga = rootGetters['auth/StateUser'].active_org;
    return $axios.get(`orgs/${orga}/zones`)
        .then((response) => {
            if (response.data.zones.length > 0){
                response.data.zones.forEach((el) => {
                    zones.push({
                        id: el.id,
                        name: el.name,
                        city: el.city,
                        shape: el.shape ? el.shape : null,
                        nodes: el.nodes ? el.nodes : null,
                    });
                });
            }
            commit('setZoneList', zones);
            return response.data;
        })
        .catch((error) => {
            commit('setZoneList', null);
            console.log('ZONES: there was an error getting the zones : ' + error);
        });
}


function savePolys({commit}, polys) {
    commit('setPolys', polys);
}



export default {
    getData,
    getLastData,
    getNodesList,
    getNodesOnline,
    getMetaNodes,
    getSchedulesNodes,
    getZones,
    savePolys
};