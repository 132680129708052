<template>
    <v-snackbar
        v-model="snackbar"
        multi-line
        timeout="-1"
        vertical
        >
        <div :class="msgColor" class="ma-2 msgTitle"><v-icon class="mx-2">mdi-{{ iconText }}</v-icon> {{ msgTitle }}</div>
        <v-card color="#333" elevation="0">
            <v-card-text>
                <div v-if="!multiLine && typeof msgText !== 'object'">
                    {{msgText}}
                </div>
                <div class="ma-2" v-for="line in msgText" :key="line.id" v-else>
                    <div v-if="typeof line === 'object'">
                        <div class="d-flex flex-row" v-for="key1 in Object.keys(line).length" :key="key1">
                            <div class="pl-2">{{ Object.keys(line)[key1 - 1] }}:</div>
                            <div class="pl-2 float-end">{{ Object.values(line)[key1 - 1] }}</div>
                        </div>
                    </div>
                    <div v-else>
                        {{ msgText }}
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <template #action="{ on, attrs }">
            <v-btn
                :color="msgColor"
                depressed
                v-on="on"
                v-bind="attrs"
                @click.stop="dismiss"
                >close</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: "InfoMessage",
    computed: {
        multiLine(){
            const pattern = /.*[\n]+.*/g;
            const re = new RegExp(pattern);
            return re.test(this.msgText);
        },
        iconText() {
            switch(this.msgColor){
                case 'error':
                    return 'alert-circle';
                case 'warning':
                    return 'alert';
                case 'info':
                    return 'information';
                case 'success':
                    return 'check-circle'
                default:
                    return '';
            }
        },
        snackbar() {
            return this.$store.getters['not/isMsg'];
        },
        msgColor() {
            return this.$store.getters['not/getType'];
        },
        msgText() {
            return this.$store.getters['not/getMessage'];
        },
        msgTitle() {
            return this.$store.getters['not/getTitle'];
        }
    },
    methods: {
        dismiss() {
            this.$store.commit('not/clearMessage');
        },
    },
}
</script>

<style scoped>
.msgTitle {
    font-weight: bold;
    font-size: 24px;
    padding: 10px;
    margin: 5px 10px 25px 10px;
    border: 1px solid gray;
    border-radius: 5px;
}
.msgText {
    margin: 0 15px;
}
</style>